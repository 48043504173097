/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import {
	Box,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Icon,
	IconButton,
	ListItem,
	Select,
	Text,
	Textarea,
	UnorderedList,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { DateTime } from "luxon";
import { FiChevronRight } from "react-icons/fi";

import InputField from "common/components/InputField/InputField";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";

import { HojaDeVisita } from "../HojaDeVisita";
import { correlacionesFormulario } from "../secciones/correlacionesFormulario";
import { titulosSecciones } from "../secciones/titulosSecciones";
import { useAutoInputSend } from "../secciones/useAutoInputSend";
import {
	useContactFieldChangeDetector,
	useContactInputDiffDetector,
	useInputDiffDetector,
} from "../secciones/useInputDiffDetector";

interface ResolucionProps {
	openModal: () => void;
}

export const Resolucion: React.FC<ResolucionProps> = ({ openModal }) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const dataSeccion3Anterior = useAppSelector(
		(state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data?.seccion_3,
	);
	const dataSeccion3 = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_3);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	const debouncedChangeHandler = React.useMemo(
		() =>
			debounce(
				(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
					sendInputValue(event.target.name, event.target.value),
				500,
			),
		[evaluacion],
	);

	const next14Months = React.useMemo(() => {
		const fechaVisitaDate = new Date();
		const months: Date[] = [];
		for (let i = 1; i < 13; i++) {
			const newDate = new Date(fechaVisitaDate.getFullYear(), fechaVisitaDate.getMonth(), 2);
			newDate.setMonth(newDate.getMonth() + i);
			months.push(newDate);
		}
		return months;
	}, [evaluacion]);

	const listadoSecciones = Object.keys(correlacionesFormulario);
	const listadoSeccionesKey = [
		"seccion_1",
		"seccion_2",
		"seccion_3",
		"seccion_4",
		"seccion_5",
		"seccion_6",
		"seccion_7",
		"seccion_8",
		"seccion_9",
	];

	const changesSections = [
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_1"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_2"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_3"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_4"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_5"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_6"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_7"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_8"),
		useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_9"),
	];

	const fieldHasChanged = useContactFieldChangeDetector(evaluacionAnterior, evaluacion);

	const contactsFields = {
		nombre_contacto: "Nombre",
		apellidos_contacto: "Apellidos",
		cargo_contacto: "Cargo",
		email_contacto: "Email",
		telefono_contacto: "Teléfono",
	};

	const contactHasChanged = useContactInputDiffDetector(evaluacionAnterior, evaluacion);

	const desayunosAnterior = (
		(Number(dataSeccion3Anterior?.unidades_desayuno) *
			(Number(dataSeccion3Anterior?.porcentaje_desayunos) / 100) *
			Number(dataSeccion3Anterior?.dias_semana_desayunos)) /
		7
	).toFixed(2);

	const meriendasAnterior = (
		(Number(dataSeccion3Anterior?.unidades_merienda) *
			(Number(dataSeccion3Anterior?.porcentaje_meriendas) / 100) *
			Number(dataSeccion3Anterior?.dias_semana_meriendas)) /
		7
	).toFixed(2);

	const comidasAnterior = (
		(Number(dataSeccion3Anterior?.unidades_comida) *
			(Number(dataSeccion3Anterior?.porcentaje_comidas) / 100) *
			Number(dataSeccion3Anterior?.dias_semana_comidas)) /
		7
	).toFixed(2);

	const cenasAnterior = (
		(Number(dataSeccion3Anterior?.unidades_cena) *
			(Number(dataSeccion3Anterior?.porcentaje_cenas) / 100) *
			Number(dataSeccion3Anterior?.dias_semana_cenas)) /
		7
	).toFixed(2);

	const totalPersonasEquivalentesAnterior = (
		Number(desayunosAnterior) +
		Number(comidasAnterior) +
		Number(meriendasAnterior) +
		Number(cenasAnterior)
	).toFixed(2);

	const desayunos = (
		(Number(dataSeccion3?.unidades_desayuno) *
			(Number(dataSeccion3?.porcentaje_desayunos) / 100) *
			Number(dataSeccion3?.dias_semana_desayunos)) /
		7
	).toFixed(2);

	const meriendas = (
		(Number(dataSeccion3?.unidades_merienda) *
			(Number(dataSeccion3?.porcentaje_meriendas) / 100) *
			Number(dataSeccion3?.dias_semana_meriendas)) /
		7
	).toFixed(2);

	const comidas = (
		(Number(dataSeccion3?.unidades_comida) *
			(Number(dataSeccion3?.porcentaje_comidas) / 100) *
			Number(dataSeccion3?.dias_semana_comidas)) /
		7
	).toFixed(2);

	const cenas = (
		(Number(dataSeccion3?.unidades_cena) *
			(Number(dataSeccion3?.porcentaje_cenas) / 100) *
			Number(dataSeccion3?.dias_semana_cenas)) /
		7
	).toFixed(2);

	const totalPersonasEquivalentes = (Number(desayunos) + Number(comidas) + Number(meriendas) + Number(cenas)).toFixed(
		2,
	);

	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_4");
	const maxF = changeDetector("maximo_kilos_fega_frutas_mes")["data-ischanged"];
	const maxH = changeDetector("maximo_kilos_fega_hortalizas_mes")["data-ischanged"];
	const numeroTurnosFEGA = React.useMemo(() => {
		return (
			(evaluacion?.seccion_4?.fyh_1er_turno ? 1 : 0) +
			(evaluacion?.seccion_4?.fyh_2do_turno ? 1 : 0) +
			(evaluacion?.seccion_4?.fyh_3er_turno ? 1 : 0) +
			(evaluacion?.seccion_4?.fyh_4to_turno ? 1 : 0)
		);
	}, []);

	return (
		<Box>
			{evaluacion && <HojaDeVisita evaluacion={evaluacion} />}
			<Divider my={4} />
			<Box overflowY="auto" maxH="50vh" pb="2rem">
				<Grid templateColumns="repeat(2, 1fr)" gap={10}>
					<GridItem colSpan={1}>
						<Text textStyle="body1" color="black" fontWeight="bold" mb="1rem">
							Datos actualizados
						</Text>
						<React.Fragment>
							{listadoSecciones.map((seccion, index) => {
								const seccionKey = listadoSeccionesKey[index];
								const changesSection = changesSections[index];
								const currentSectionData = evaluacion?.[seccionKey];
								const anyFieldChanged = Object.keys(correlacionesFormulario[seccion]).some((field) => {
									return changesSection(field)["data-ischanged"] === "true";
								});

								if (
									(evaluacionAnterior && anyFieldChanged) ||
									(evaluacionAnterior &&
										seccionKey === "seccion_3" &&
										totalPersonasEquivalentesAnterior !== totalPersonasEquivalentes)
								) {
									return (
										<Box key={seccionKey}>
											<Text textStyle="body2" fontWeight={"bold"}>
												Sección {index + 1}: {titulosSecciones[seccion]}
											</Text>

											{Object.keys(correlacionesFormulario[seccion]).map((field) => {
												const isChanged = changesSection(field)["data-ischanged"];
												const currentValue = currentSectionData ? currentSectionData[field] : null;
												if (isChanged === "true") {
													return (
														<UnorderedList mt=".5rem" textStyle="body2" color="black" mb=".5rem" key={field}>
															<ListItem>
																<Flex pb=".15rem" alignItems="center">
																	<Text color="gray.600" textStyle="body3" mr=".5rem">
																		{correlacionesFormulario[seccion][field]}:
																	</Text>
																	<Text color="black" textStyle="body3">
																		{correlacionesFormulario[seccion][field].includes("¿") &&
																		(currentValue === 1 || currentValue === 0)
																			? currentValue === 1
																				? "Si"
																				: "No"
																			: typeof currentValue === "number" && !Number.isInteger(currentValue)
																			? currentValue.toFixed(0)
																			: currentValue}
																	</Text>
																</Flex>
															</ListItem>
														</UnorderedList>
													);
												}

												return null;
											})}
											{seccionKey === "seccion_1" &&
												evaluacion?.seccion_1.contacts &&
												evaluacion?.seccion_1.contacts.length > 0 && (
													<React.Fragment>
														{evaluacion.seccion_1.contacts.map((contact) => {
															if (!contact) return null;
															const contactChanged = contactHasChanged(
																contact.nombre_contacto,
																contact.apellidos_contacto,
															)["data-ischanged"];
															return (
																<Box key={contact.id}>
																	{contactChanged === "true" && (
																		<Text textStyle="body3" color="gray.600">
																			Contacto Actualizado: {contact.nombre_contacto} {contact.apellidos_contacto}
																		</Text>
																	)}
																	{contactsFields &&
																		Object.keys(contactsFields).map((field) => {
																			const isChanged = fieldHasChanged(contact, field)["data-ischanged"];
																			const currentValue = contact ? contact[field] : null;
																			if (isChanged === "true") {
																				return (
																					<UnorderedList
																						mt=".5rem"
																						textStyle="body2"
																						color="black"
																						mb=".5rem"
																						key={field}
																					>
																						<ListItem>
																							<Flex pb=".15rem" alignItems="center">
																								<Text color="gray.600" textStyle="body3" mr=".5rem">
																									{contactsFields[field]}:
																								</Text>
																								<Text color="black" textStyle="body3">
																									{currentValue}
																								</Text>
																							</Flex>
																						</ListItem>
																					</UnorderedList>
																				);
																			}

																			return null;
																		})}
																</Box>
															);
														})}
													</React.Fragment>
												)}
											{seccionKey === "seccion_3" &&
												totalPersonasEquivalentesAnterior !== totalPersonasEquivalentes &&
												evaluacion?.seccion_1?.tipo_reparto.toLowerCase() === "consumo" && (
													<Flex pb=".15rem" alignItems="center" m="1rem 0">
														<Text color="gray.600" textStyle="body3" mr=".5rem">
															Total personas equivalentes:
														</Text>
														<Text color="black" textStyle="body3">
															{totalPersonasEquivalentes}
														</Text>
													</Flex>
												)}
										</Box>
									);
								}

								return null;
							})}

							<Divider margin="1.5rem 0" color="gray.200" />
							<Grid templateColumns="repeat(5, 1fr)" gap={2} alignItems="center">
								<GridItem colSpan={3}>
									<Text textStyle="body2">Factor de distribución:</Text>
								</GridItem>
								<GridItem colSpan={2}>
									<Text textStyle="body2" color="gray.700" ml=".25rem">
										{formatNumber(evaluacion?.seccion_4?.factor_distribucion)}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<FormLabel>Factor distribución actualizado</FormLabel>
								</GridItem>
								<GridItem colSpan={2}>
									<InputField
										name="factor_distribucion_modificado"
										defaultValue={formatNumber(evaluacion?.seccion_4.factor_distribucion_modificado)}
										isDisabled={evaluacion?.estado_evaluacion === "Validada"}
										w="80%"
									/>
								</GridItem>
								<GridItem colSpan={3}>
									<FormLabel>Max kg. fruta por turno:</FormLabel>
								</GridItem>
								<GridItem colSpan={2}>
									<InputField
										name="maximo_kilos_fega_frutas_mes"
										defaultValue={evaluacion?.seccion_4.maximo_kilos_fega_frutas_mes}
										{...changeDetector("maximo_kilos_fega_frutas_mes")}
										isDisabled={evaluacion?.estado_evaluacion === "Validada"}
										w="80%"
									/>
								</GridItem>
								<GridItem colSpan={3}>
									<FormLabel>Max kg. hortaliza por turno:</FormLabel>
								</GridItem>
								<GridItem colSpan={2}>
									<InputField
										name="maximo_kilos_fega_hortalizas_mes"
										defaultValue={evaluacion?.seccion_4.maximo_kilos_fega_hortalizas_mes}
										{...changeDetector("maximo_kilos_fega_hortalizas_mes")}
										isDisabled={evaluacion?.estado_evaluacion === "Validada"}
										w="80%"
									/>
								</GridItem>
								<GridItem colSpan={3}>
									<Text textStyle="body2">Max. kg. FyH por turno:</Text>
								</GridItem>
								<GridItem colSpan={2}>
									<Text
										textStyle="body2"
										color={maxF === "true" || maxH === "true" ? "green.400" : "gray.700"}
										ml=".25rem"
									>
										{formatNumber(
											Number(evaluacion?.seccion_4.maximo_kilos_fega_frutas_mes) +
												Number(evaluacion?.seccion_4.maximo_kilos_fega_hortalizas_mes),
										)}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text textStyle="body2">Nº turnos FEGA:</Text>
								</GridItem>
								<GridItem colSpan={2}>
									<Text textStyle="body2" color="gray.700" ml=".25rem">
										{numeroTurnosFEGA}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text textStyle="body2">Max. kg. frutas por mes:</Text>
								</GridItem>
								<GridItem colSpan={2}>
									<Text textStyle="body2" color={maxF === "true" ? "green.400" : "gray.700"} ml=".25rem">
										{formatNumber(numeroTurnosFEGA * Number(evaluacion?.seccion_4.maximo_kilos_fega_frutas_mes))}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text textStyle="body2">Max. kg. hortalizas por mes:</Text>
								</GridItem>
								<GridItem colSpan={2}>
									<Text textStyle="body2" color={maxH === "true" ? "green.400" : "gray.700"} ml=".25rem">
										{formatNumber(numeroTurnosFEGA * Number(evaluacion?.seccion_4.maximo_kilos_fega_hortalizas_mes))}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text textStyle="body2">Max. kg. FyH por mes:</Text>
								</GridItem>
								<GridItem colSpan={2}>
									<Text
										textStyle="body2"
										color={maxF === "true" || maxH === "true" ? "green.400" : "gray.700"}
										ml=".25rem"
									>
										{formatNumber(
											numeroTurnosFEGA *
												(Number(evaluacion?.seccion_4.maximo_kilos_fega_frutas_mes) +
													Number(evaluacion?.seccion_4.maximo_kilos_fega_hortalizas_mes)),
										)}
									</Text>
								</GridItem>
							</Grid>
						</React.Fragment>
					</GridItem>
					<GridItem colSpan={1}>
						<Text textStyle="body1" color="black" fontWeight="bold" mb="1rem">
							Resumen final
						</Text>
						<FormControl>
							<FormLabel>Siguiente visita</FormLabel>
							<Select
								name="fecha_proxima_visita"
								onChange={debouncedChangeHandler}
								defaultValue={DateTime.fromJSDate(new Date(evaluacion?.fecha_proxima_visita || "")).toFormat(
									"yyyy-MM-dd",
								)}
								isDisabled={
									evaluacion?.estado_evaluacion === "Validada" || evaluacion?.estado_evaluacion === "Supervisada"
								}
							>
								{next14Months.map((date) => (
									<option key={date.toISOString()} value={DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")}>
										En&nbsp;
										{DateTime.fromJSDate(date).toFormat("MMMM", {
											locale: "es-ES",
										})}
										&nbsp;de&nbsp;{DateTime.fromJSDate(date).toFormat("yyyy")}
									</option>
								))}
							</Select>
						</FormControl>
						<Text mt="1rem" textStyle="body2" color="black" mb=".5rem">
							Resumen del visitador
						</Text>
						<Textarea isDisabled={true} defaultValue={evaluacion?.seccion_10.resumen_visitador || ""} />
						<Text mt="1rem" textStyle="body2" color="black" mb=".5rem">
							Resumen del supervisor
						</Text>
						<Textarea
							name="resumen_supervisor"
							isDisabled={
								evaluacion?.estado_evaluacion === "Supervisada" || evaluacion?.estado_evaluacion === "Validada"
							}
							defaultValue={evaluacion?.resumen_supervisor || ""}
							mb="1rem"
							onChange={debouncedChangeHandler}
						/>
						<Text mt="1rem" textStyle="body2" color="black" mb=".5rem">
							Resumen del administrador
						</Text>
						<Textarea
							name="resumen_administrador"
							isDisabled={
								evaluacion?.estado_evaluacion === "Completada" || evaluacion?.estado_evaluacion === "Validada"
							}
							defaultValue={evaluacion?.resumen_administrador || ""}
							mb="1rem"
							onChange={debouncedChangeHandler}
						/>
					</GridItem>
				</Grid>
			</Box>
			<Divider my={4} />
			<Flex justifyContent="flex-end" mt={7}>
				<IconButton
					aria-label="validate"
					isDisabled={evaluacion?.estado_evaluacion === "Validada"}
					icon={<Icon as={FiChevronRight} />}
					variant="solid"
					colorScheme="teal"
					onClick={openModal}
					_active={{ bg: "gray.400" }}
				/>
			</Flex>
		</Box>
	);
};
