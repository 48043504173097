import React from "react";

import { Grid, GridItem, GridItemProps, Icon, Text, TextProps, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FiAlertTriangle, FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { BatteryChart } from "common/components/BatteryChart";
import StatusIndicator from "common/components/StatusIndicator";
import { EntidadOutput } from "config/types/entidad";

interface RowDataProps {
	entidad: EntidadOutput;
	index: number;
}

export const RowData: React.FC<RowDataProps> = ({ entidad, index }) => {
	const textProps: TextProps = {
		textStyle: "body2",
		color: "black",
	};

	const gridItemProps: GridItemProps = {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	};

	const navigate = useNavigate();

	// Display tooltip if text is overflown
	const [isOverflown, setIsOverflown] = React.useState(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	React.useEffect(() => {
		const element = ref.current;
		element && setIsOverflown(element.scrollWidth > element.clientWidth);
	}, []);

	const goToEntidadEvaluacion = () => {
		navigate(`/${entidad.cod_eb}/${entidad.numero_evaluacion}`);
	};

	const marginStyle = index !== 0 ? "1rem 0" : "0";

	return (
		<Grid
			templateColumns="repeat(11, 1fr)"
			gap={5}
			backgroundColor="white"
			borderRadius="0.375rem"
			m={marginStyle}
			p="1rem 1rem"
			maxH="3rem"
			onClick={goToEntidadEvaluacion}
			_hover={{
				cursor: "pointer",
				shadow: "md",
			}}
			alignContent="center"
		>
			<GridItem colSpan={1} {...gridItemProps}>
				<Text {...textProps}>{entidad.cod_eb}</Text>
			</GridItem>
			<GridItem colSpan={3} {...gridItemProps}>
				<Tooltip label={entidad.nombre_eb} placement="top" hasArrow isDisabled={!isOverflown}>
					<Text {...textProps} isTruncated ref={ref}>
						{entidad.nombre_eb}
					</Text>
				</Tooltip>
			</GridItem>
			<GridItem colSpan={1} {...gridItemProps}>
				<Text {...textProps}>{entidad.tipo_reparto}</Text>
			</GridItem>
			<GridItem colSpan={1} {...gridItemProps}>
				{entidad.relevancia && <BatteryChart value={Number(entidad.relevancia)} />}
			</GridItem>
			<GridItem colSpan={1} {...gridItemProps}>
				<Text {...textProps}>{entidad.grupo_visitador}</Text>
			</GridItem>
			<GridItem colSpan={1} {...gridItemProps}>
				<Text {...textProps}>
					{DateTime.fromJSDate(new Date(entidad.fecha_programada)).setLocale("es").toFormat("LLL y")}
				</Text>
			</GridItem>
			<GridItem colSpan={1} {...gridItemProps}>
				<StatusIndicator estado={entidad.estado_evaluacion} />
			</GridItem>
			<GridItem colSpan={1} {...gridItemProps} display="flex" justifyContent="center">
				{entidad.existen_anomalias > 0 && (
					<Icon as={FiAlertTriangle} fill="pink.500" color="white" fontSize="1.35rem" strokeWidth="1" mr="2rem" />
				)}
			</GridItem>
			<Icon as={FiChevronRight} color="gray.500" fontSize="1.5rem" strokeWidth="1" justifySelf="flex-end" />
		</Grid>
	);
};
