import React from "react";

import { Box, Divider, FormControl, FormLabel, Grid, GridItem, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

import InputField from "common/components/InputField/InputField";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion03: React.FC<ISeccionProps> = (props) => {
	const [validationError, setValidationError] = React.useState<string | null>(null);

	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataAnterior = useAppSelector(
		(state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data?.seccion_3,
	);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_3);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_3_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	function calculateAndRound(value?: number, percentage?: number, days?: number) {
		return parseFloat(((Number(value) * (Number(percentage) / 100) * Number(days)) / 7).toFixed(2));
	}

	const desayunos = calculateAndRound(
		seccionDataActual?.unidades_desayuno,
		seccionDataActual?.porcentaje_desayunos,
		seccionDataActual?.dias_semana_desayunos,
	);

	const meriendas = calculateAndRound(
		seccionDataActual?.unidades_merienda,
		seccionDataActual?.porcentaje_meriendas,
		seccionDataActual?.dias_semana_meriendas,
	);

	const comidas = calculateAndRound(
		seccionDataActual?.unidades_comida,
		seccionDataActual?.porcentaje_comidas,
		seccionDataActual?.dias_semana_comidas,
	);

	const cenas = calculateAndRound(
		seccionDataActual?.unidades_cena,
		seccionDataActual?.porcentaje_cenas,
		seccionDataActual?.dias_semana_cenas,
	);

	const totalPersonasEquivalentes = Number(desayunos) + Number(comidas) + Number(meriendas) + Number(cenas);
	const totalPorcentajesModificados = React.useMemo(() => {
		return (
			Number(seccionDataActual?.porcentaje_desayunos) +
			Number(seccionDataActual?.porcentaje_comidas) +
			Number(seccionDataActual?.porcentaje_meriendas) +
			Number(seccionDataActual?.porcentaje_cenas)
		);
	}, [
		seccionDataActual?.porcentaje_desayunos,
		seccionDataActual?.porcentaje_comidas,
		seccionDataActual?.porcentaje_meriendas,
		seccionDataActual?.porcentaje_cenas,
	]);
	const totalPorcentajesOriginales = React.useMemo(() => {
		return (
			Number(seccionDataAnterior?.porcentaje_desayunos) +
			Number(seccionDataAnterior?.porcentaje_comidas) +
			Number(seccionDataAnterior?.porcentaje_meriendas) +
			Number(seccionDataAnterior?.porcentaje_cenas)
		);
	}, [
		seccionDataAnterior?.porcentaje_desayunos,
		seccionDataAnterior?.porcentaje_comidas,
		seccionDataAnterior?.porcentaje_meriendas,
		seccionDataAnterior?.porcentaje_cenas,
	]);

	const beneficiariosTotales = evaluacion?.seccion_2.beneficiarios_totales || 0;

	const validateInputFields = () => {
		const totalPersonasEquivalentes = desayunos + comidas + meriendas + cenas;

		if (totalPersonasEquivalentes > beneficiariosTotales) {
			setValidationError(
				`Total personas equivalentes (${totalPersonasEquivalentes.toFixed(
					2,
				)}) es mayor a beneficiarios totales (${beneficiariosTotales}).`,
			);
		} else {
			setValidationError(null);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_3");

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(4);

	React.useEffect(() => {
		validateInputFields();
	}, [seccionDataActual]);

	return (
		<Box>
			<SeccionHeader seccion="03" />
			<Box width="100%">
				<Divider color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1rem" style={{ pageBreakInside: "avoid" }} mt="1.5rem">
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Porcentajes originales
						</Text>
						{totalPorcentajesOriginales > 100 && (
							<HStack>
								<Icon as={FiAlertTriangle} boxSize="2rem" color="red" h="2rem" w="2rem" />
								<Text color="red.500">La suma de personajes no debe superar el 100% </Text>
							</HStack>
						)}
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Desayunos %</FormLabel>
							<InputField isDisabled defaultValue={seccionDataAnterior?.porcentaje_desayunos} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Comidas %</FormLabel>
							<InputField isDisabled defaultValue={seccionDataAnterior?.porcentaje_comidas} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Meriendas %</FormLabel>
							<InputField isDisabled defaultValue={seccionDataAnterior?.porcentaje_meriendas} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Cenas %</FormLabel>
							<InputField isDisabled defaultValue={seccionDataAnterior?.porcentaje_cenas} />
						</FormControl>
					</GridItem>
				</Grid>

				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" marginTop="1.64rem" fontWeight="700">
							Porcentajes modificados
						</Text>
						{totalPorcentajesModificados > 100 && (
							<HStack>
								<Icon as={FiAlertTriangle} boxSize="2rem" color="red" h="2rem" w="2rem" />
								<Text color="red.500">La suma de personajes no debe superar el 100% </Text>
							</HStack>
						)}
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Desayunos %</FormLabel>
							<InputField
								name="porcentaje_desayunos"
								defaultValue={seccionDataActual?.porcentaje_desayunos}
								{...changeDetector("porcentaje_desayunos")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Comidas %</FormLabel>
							<InputField
								name="porcentaje_comidas"
								defaultValue={seccionDataActual?.porcentaje_comidas}
								{...changeDetector("porcentaje_comidas")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Meriendas %</FormLabel>
							<InputField
								name="porcentaje_meriendas"
								defaultValue={seccionDataActual?.porcentaje_meriendas}
								{...changeDetector("porcentaje_meriendas")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Cenas %</FormLabel>
							<InputField
								name="porcentaje_cenas"
								defaultValue={seccionDataActual?.porcentaje_cenas}
								{...changeDetector("porcentaje_cenas")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Número de ingestas que se reparten
						</Text>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Desayunos</FormLabel>
							<InputField
								name="unidades_desayuno"
								defaultValue={seccionDataActual?.unidades_desayuno}
								{...changeDetector("unidades_desayuno")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Comidas</FormLabel>
							<InputField
								name="unidades_comida"
								defaultValue={seccionDataActual?.unidades_comida}
								{...changeDetector("unidades_comida")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Meriendas</FormLabel>
							<InputField
								name="unidades_merienda"
								defaultValue={seccionDataActual?.unidades_merienda}
								{...changeDetector("unidades_merienda")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Cenas</FormLabel>
							<InputField
								name="unidades_cena"
								defaultValue={seccionDataActual?.unidades_cena}
								{...changeDetector("unidades_cena")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº días/semana Desayunos</FormLabel>
								<InputField
									name="dias_semana_desayunos"
									defaultValue={seccionDataActual?.dias_semana_desayunos}
									{...changeDetector("dias_semana_desayunos")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº días/semana Comidas</FormLabel>
								<InputField
									name="dias_semana_comidas"
									defaultValue={seccionDataActual?.dias_semana_comidas}
									{...changeDetector("dias_semana_comidas")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-end" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº días/semana Meriendas</FormLabel>
								<InputField
									name="dias_semana_meriendas"
									defaultValue={seccionDataActual?.dias_semana_meriendas}
									{...changeDetector("dias_semana_meriendas")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl height="100%">
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel flex={1}>Nº días/semana Cenas</FormLabel>
								<InputField
									name="dias_semana_cenas"
									defaultValue={seccionDataActual?.dias_semana_cenas}
									{...changeDetector("dias_semana_cenas")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />

				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<Text textStyle="body2" color="black" fontWeight="700">
							Personas equivalentes
						</Text>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Desayunos</FormLabel>
							<InputField isDisabled value={formatNumber(desayunos)} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Comidas</FormLabel>
							<InputField isDisabled value={formatNumber(comidas)} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Meriendas</FormLabel>
							<InputField isDisabled value={formatNumber(meriendas)} />
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Cenas</FormLabel>
							<InputField isDisabled value={formatNumber(cenas)} />
						</FormControl>
					</GridItem>
					<GridItem colSpan={3}>
						{validationError && (
							<React.Fragment>
								<Text textStyle="body2" color="red" fontWeight="bold" mb=".25rem">
									Error:
								</Text>
								<Text textStyle="body3" color="red">
									{validationError}
								</Text>
								<Text textStyle="body3" color="red">
									Por favor corrija la información.
								</Text>
							</React.Fragment>
						)}
					</GridItem>
					<GridItem>
						<FormControl>
							<FormLabel>Total</FormLabel>
							<InputField isDisabled value={formatNumber(totalPersonasEquivalentes)} />
						</FormControl>
					</GridItem>
				</Grid>
			</Box>
			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_3_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_3_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled || validationError !== null}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion03;
