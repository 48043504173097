import { EntidadOutput } from "config/types/entidad";

type headerInfo = {
	label: string;
	span: number;
	column: keyof EntidadOutput;
};

export const headerData: headerInfo[] = [
	{
		label: "Código",
		span: 1,
		column: "cod_eb",
	},
	{
		label: "Entidad Beneficiaria",
		span: 3,
		column: "nombre_eb",
	},
	{
		label: "Actividad",
		span: 1,
		column: "tipo_reparto",
	},
	{
		label: "Relevancia",
		span: 1,
		column: "relevancia",
	},
	{
		label: "Grupo",
		span: 1,
		column: "grupo_visitador",
	},
	{
		label: "Program.",
		span: 1,
		column: "fecha_programada",
	},
	{
		label: "Estado",
		span: 1,
		column: "estado_evaluacion",
	},
	{
		label: "Anomalías",
		span: 1,
		column: "existen_anomalias",
	},
];
