import React from "react";

import { Flex, Text, HStack, Divider, IconButton, Icon, useDisclosure, Box } from "@chakra-ui/react";
import { FiMapPin } from "react-icons/fi";

import { Mapa, Marker } from "common/components/Mapa/Mapa";
import { EntidadOutput } from "config/types/entidad";
import { EvaluacionOutput } from "config/types/evaluacion";

import { getEntidadCoordinates } from "../ModalMapa/getEntidadesCoordinates";
import { ModalMapa } from "../ModalMapa/ModalMapa";

interface EntidadProps {
	entidad: EntidadOutput;
	evaluacion: EvaluacionOutput;
}

export const DatosEntidad: React.FC<EntidadProps> = ({ entidad, evaluacion }) => {
	const { isOpen: isOpenMapa, onOpen: onOpenMapa, onClose: onCloseMapa } = useDisclosure();

	const [loading, setLoading] = React.useState(true);

	const data = React.useMemo(() => {
		if (!entidad || !evaluacion) return null;
		return [
			{
				label: "Código entidad",
				value: entidad.cod_eb,
			},
			{
				label: "Actividad",
				value: entidad.tipo_de_actividad,
			},
			{
				label: "Grupo visitadores",
				value: entidad.grupo_visitador,
			},
			{
				label: "Grupo homogeneo",
				value: entidad.grupo_homogeneo,
			},
			{
				label: "Situación",
				value: evaluacion.seccion_1.status,
			},
			{
				label: "NIF",
				value: evaluacion.seccion_1?.cif,
			},
			{
				label: "Registro",
				value: evaluacion.seccion_1?.registro,
			},
			{
				label: "Convenio",
				value: evaluacion.seccion_1?.convenio,
			},
			// {
			// 	label: "Estatutos",
			// 	value: evaluacion.seccion_1?.tiene_estatutos ? "Sí" : "No",
			// },
			// {
			// 	label: "Compromisos mutuos",
			// 	value: evaluacion.seccion_1?.compromisos_mutuos ? "Sí" : "No",
			// },
			{
				label: "Correo de contacto",
				value: evaluacion.seccion_1?.email,
			},
			{
				label: "Dirección actividad",
				value: evaluacion.seccion_1?.direccion_actividad,
			},
			{
				label: "Municipio",
				value: entidad.municipio_actividad,
			},
			{
				label: "Codigo postal",
				value: evaluacion.seccion_1?.codpost_actividad,
			},
		];
	}, [entidad, evaluacion]);

	const dataMapa = React.useMemo(() => {
		if (!entidad || !evaluacion) return null;
		return {
			nombre_eb: entidad.nombre_eb,
			direccion_actividad: entidad.direccion_actividad,
			municipio_actividad: entidad.municipio_actividad,
			codigo_postal_actividad: evaluacion.seccion_1?.direccion_postal_codpost_actividad,
			latitud: entidad.latitud,
			longitud: entidad.longitud,
		};
	}, [entidad, evaluacion]);

	const [marker, setMarker] = React.useState<Marker>();

	React.useEffect(() => {
		if (dataMapa) {
			getEntidadCoordinates(dataMapa, (newMarker) => {
				setMarker(newMarker);
				setLoading(false);
			});
		}
	}, [dataMapa]);

	return (
		<Flex direction="column" align="flex-start" justify="flex-start">
			<Divider mt="1.5rem" />
			<Box w="100%" display={{ sm: "flex", md: "none" }}>
				{marker && <Mapa marker={marker} />}
			</Box>
			<HStack w="100%" justify="flex-end" position="relative" top="1.5rem" display={{ sm: "none", md: "flex" }}>
				<IconButton aria-label="back" variant="icon-only" backgroundColor="white" onClick={onOpenMapa}>
					<Icon as={FiMapPin} color="gray.500" fontSize="1.25rem" strokeWidth="1" />
				</IconButton>
			</HStack>
			{data &&
				data.map((item, index) => (
					<React.Fragment key={index}>
						<HStack w="100%" alignItems="flex-start">
							<Text
								textStyle="body2"
								color="gray.600"
								m=".1rem 0"
								pb={item.label === "Código entidad" ? ".5rem" : "0"}
								flexShrink={0}
								minWidth="max-content"
							>
								{item.label}:
							</Text>
							<Text
								textStyle="body2"
								color="black"
								fontWeight={item.label === "Código entidad" ? "bold" : "normal"}
								pb={item.label === "Código entidad" ? ".5rem" : "0"}
							>
								{item.value}
							</Text>
						</HStack>
						{item.label === "Situación" && <Divider m="1rem 0" />}
					</React.Fragment>
				))}
			{!loading &&
				dataMapa &&
				marker &&
				marker.geometry.coordinates[0] !== 0 &&
				marker.geometry.coordinates[1] !== 0 && (
					<ModalMapa isOpen={isOpenMapa} onClose={onCloseMapa} dataMapa={dataMapa} marker={marker} />
				)}
		</Flex>
	);
};
